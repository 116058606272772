.safety {
  width: 100%;
  border-bottom: 5px solid #f59125;
  background: white;
}

.headline {
  background-color: #21bba7;;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: white;
  font-size: 18px
}

.cc {
  display: block;
  width: auto;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  color: black;
  height: 25px;
  line-height: 25px;
  border-radius: 3px;
  float: left;
  border: 1px solid grey;
  margin: 0 4px 0 4px;
  background: #f2f2f2;
  box-shadow: 2px 2px 2px darkgray;
  cursor: pointer
}

.cc:hover {
  color: black;
  background: rgba(1, 2, 1, 0.23)
}

.all {
  margin-left: 40px;
  margin-top: 50px;
  display: block
}

.modified p {
  width: 100%;
  margin-left: 50px;
  margin-top: 10px;
  color: #858585;
  font-size: 14px
}

.appear {
  width: 100%;
  height: auto;
}

.appear span {
  display: block;
  float: left;
  margin-right: 400px;
  margin-left: 100px
}

.appear {
  width: 95%;
  height: auto;
  margin: 100px auto
}

.info {
  font-size: 14px;
  margin: auto
}

.info a {
  text-decoration: none;
  color: black
}

.list a {
  color: lightgrey;
}

.emldeta {
  margin: 2px
}

.info table tr {
  border-top: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey
}

.my {
  overflow: hidden
}

.my table {
  border-top: 1px solid lightgrey;
}

.my table tr td .emldeta {
  margin: 2px
}

.my {
  border-top: 3px solid #21bba7;
  border-bottom: 3px solid #21bba7
}

.select img {
  text-align: center
}

.emldeta {
  text-align: center
}

.site {
  text-align: center
}

.emldeta a {
  color: black
}

.site a {
  color: black
}

.emldeta, .topic > a {
  color: black
}

.list {
  text-align: left;
}

.list a {
  color: darkgray;
}

.state li a {
  text-decoration: none;
}